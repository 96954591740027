















































































































































































































































































































@import '@/style/custom.scss';
.filterBtn .v-input input {
  color: var(--v-textColor-base) !important;
}

.filterBtn .v-select.v-input--dense .v-select__selection--comma {
  color: var(--v-textColor-base);
}
.filterBtn .v-select.v-input--dense .v-select__selection--comma {
  color: var(--v-textColor-base);
}
.chart {
  border: 5px solid var(--v-borderColor-base);
  border-radius: 20px;
  margin: 20px;
  background-color: white;
}

.filterBtn {
  margin: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.filterBtn > * {
  margin-right: 12px !important;
}
.filterBtn
  .v-input.text-green.v-input--hide-details.v-input--is-label-active.v-input--is-dirty.v-input--dense.theme--light.v-text-field.v-text-field--single-line.v-text-field--solo.v-text-field--solo-flat.v-text-field--is-booted.v-text-field--enclosed {
  flex: 0 0 auto !important;
}
.filterBtn .theme--light.v-icon {
  color: var(--v-textColor-base) !important;
}
.filterBtn .v-application .primary--text {
  color: var(--v-textColor-base) !important;
}
.filterBtn
  .v-input.v-input--is-label-active.v-input--is-dirty.v-input--dense.theme--light.v-text-field.v-text-field--single-line.v-text-field--solo.v-text-field--solo-flat.v-text-field--is-booted.v-text-field--enclosed.v-select {
  flex: 0 0 auto;
}
.v-menu__content
  .v-list
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: var(--v-textColor-base) !important;
}

.dashboard .filterBtn i.v-icon.notranslate.mdi.mdi-menu-down.theme--light {
  font-size: 40px;
}

.reloadBtn {
  height: 40px !important;
}
.dashBoardLabel .itemFieldName {
  color: var(--v-textColor-base) !important;
}
.selectorForMonth > * {
  display: grid;
  grid-template-columns: 50px 50px 50px 50px;
  padding: 16px;
  padding-bottom: 4px;
  padding-right: 4px;
}
.selectorForMonth > * > * {
  @include menuButtonLook();
  padding: 0;
  border-radius: 4px;
  margin-bottom: 12px;
  margin-right: 12px;
}
.selectorForMonth > * > *:not(:last-child) {
}

.selectorForMonth .v-list-item__title {
  color: white !important;
  display: flex;
  justify-content: center;
}

.selectorForMonth .v-list .v-list-item--active {
  @include selectedColor();
}

// .dashboard.v-menu__content ::v-deep {
//   color: black;
// }
