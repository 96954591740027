.itemFieldName {
  font-weight: 600;

  font-size: 14px;
}
.mainTitle {
  color: var(--v-textColor-base);
  font-size: 17px;
  font-weight: bold;
}

.v-input__slot {
  /*   width: 200px !important;
  min-height: 40px !important;
  padding-bottom: 0 !important; */
}
i.v-icon.notranslate.mdi.mdi-magnify.theme--light {
  color: var(--v-searchColor-base);
}
.textLabel {
  font-size: 14px;
}
.subTitle {
  color: var(--v-mainTitleLabel-base);
  font-size: 14px;
  //font-weight: 600;
}
.mdi-reload::before {
  color: var(--v-drawerHover-base);
}
.mdi-plus-thick::before {
  color: var(--v-drawerHover-base);
}

.inputInfo {
  display: flex;
  justify-content: center !important;
  align-items: center;
  width: 100%;
  background-color: white;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
@mixin menuButtonLook {
  background-color: var(--v-filterButtonColor-base) !important;
  color: white !important;
}
@mixin selectedColor {
  background: linear-gradient(
    to right,
    var(--v-buttonDeepBlue-base) 45%,
    var(--v-buttonLightBlue-base)
  );
}
