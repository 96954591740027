






























































































































































.activityManagement ::v-deep .v-data-table {
  border: 6px solid var(--v-borderColor-base);
  border-radius: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  font-size: 20px;
}

.activityManagement ::v-deep .v-data-table-header {
  background-image: linear-gradient(to right, rgb(58, 196, 251) 25%, rgb(3, 139, 249));
  font-size: 2rem !important;
  text-align: center;
}

.activityManagement .v-data-table::v-deep th {
  font-size: 20px !important;
}
.activityManagement .v-data-table::v-deep td {
  font-size: 12px !important;
}

.activityManagement ::v-deep .v-data-table.row-height-50 td {
  height: 30px;
}
.activityManagement ::v-deep td.text-center {
  border: 1px solid rgb(55, 158, 250);
}

::v-deep .v-input__slot {
  width: 200px !important;
  min-height: 40px !important;
  padding-bottom: 0 !important;
}
