





















































































































































































































































































































@import '@/style/custom.scss';
.menuDropDown {
  background-color: white;
  width: 150px;
  height: 40px;
  border-radius: 4px;
  align-items: center;
  cursor: pointer;
}

/* .identityCardButton {
  .clearPadding:not(:last-of-type) {
    padding-right: 0px;
  }
} */

/* @mixin menuButtonLook {
  background-color: var(--v-filterButtonColor-base) !important;
  width: 100%;
  color: white !important;
} */
.selectorButton {
  width: 100%;
  @include menuButtonLook();
}
.btnFilter {
  @include selectedColor;
}
.resetAndApply > * {
  border: 2px solid var(--v-borderColor-base);
  color: var(--v-textColor-base) !important;
}
#identity {
  ::v-deep .v-input__slot {
    width: 200px !important;
    min-height: 40px !important;
    padding-bottom: 0 !important;
  }
}
